import routes from '../../navigation/routes'
import TabbedPage from '../../navigation/Tabs/TabbedPage'
import DealQuickView from '../DealQuickView'
import { GET_DEAL_DETAILS } from './../Queries'
import { useParams } from 'react-router-dom'
import { DYNAMIC_DEAL_SEARCH } from '../../Search/Queries'
import { EXPORT_URL } from '../../Exports/PdfExport'
import { useCallback, useContext } from 'react'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { fetchWithAuth } from '../../common/helpers'

export default function Deal(props) {
	const params = useParams()

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const downloadPropertyRecept = (props) => {
		const deal = props?.state?.getDeal
		const url = new URL(EXPORT_URL, window.location)

		// const params = new URLSearchParams()
		// params.set('template', 'consignmentPropertyReceipt')
		// params.set('header', 'Lévy Gorvy Dayan')
		// params.set('dealId', deal.id)

		// url.search = params
		const body = {
			template: "consignmentPropertyReceipt",
			header: "Lévy Gorvy Dayan",
			dealId: deal.id,
		}
		fetchWithAuth(url, {
			timeout: 15000,
			method: "POST",
			body: JSON.stringify(body),
		})
			.then(async response => {
				if (response.status !== 200) { throw(response) }
				const a = document.createElement("a")
				document.body.appendChild(a)
				a.style = "display: none"

				const blob = await response.blob()
				const url = window.URL.createObjectURL(blob)
				a.href = url
				a.download = 'export.pdf'
				a.click()
				window.URL.revokeObjectURL(url)
			})
			.catch(async err => {
				const resp = await err.json()
				const errorMessage = resp?.message ?? err.statusText
				openSnackbar(severity.ERROR, `Error: ${errorMessage}`)
				console.error(err)
			})
	}

	const extraMenuOptions = [{
		title: "Download Property Receipt",
		action: downloadPropertyRecept,
		image: "../../images/icons/Grey/Download.svg",
		predicate: props => props?.state?.getDeal?.deal_type?.value?.includes('Consignment')
	}]

	return TabbedPage({
		...props,
		routes: routes.dealPages.pages,
		Quickview: DealQuickView,
		query: GET_DEAL_DETAILS,
		searchGlobalQuery: DYNAMIC_DEAL_SEARCH,
		options: {
			...routes.dealPages.options,
			extraMenuOptions
		},
		params,
	})
}
